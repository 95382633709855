import React from "react";
import { Navbar, Container, Nav, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./navbar.css";
import logo from "../../assets/images/logo.svg";
import logoWhite from "../../assets/images/logo-white.svg";
import mobileBtn from "../../assets/images/hamburger.svg";

const Navbarr = ({ textColor }) => {
  return (
    <div className="container-fluid p-0">
      {/* <Outlet /> */}
      <Navbar expand="lg">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={textColor ? logoWhite : logo} alt="logo" height={65} />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="navbarScroll" className="border-0 p-0">
            <img src={mobileBtn} alt="logo" width={25} />
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="ms-auto my-2 my-lg-0">
              <LinkContainer to="/about">
                <Nav.Link
                  className={textColor ? "nav-link text-white" : "nav-link"}
                >
                  About
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/programme">
                <Nav.Link
                  className={textColor ? "nav-link text-white" : "nav-link"}
                >
                  Courses
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/faq">
                <Nav.Link
                  className={textColor ? "nav-link text-white" : "nav-link"}
                >
                  FAQs
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Form className="d-flex px-0 px-lg-4">
              <LinkContainer to="/register">
                <Nav.Link className="btn rounded-btn">Enroll Now</Nav.Link>
              </LinkContainer>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbarr;
