import React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import student1 from "../../assets/images/student1.svg";
import student2 from "../../assets/images/student2.svg";
import student3 from "../../assets/images/student3.svg";

const Header = () => {
  const students = [student1, student2, student3];
  return (
    <>
      <header>
        <div className="row justify-content-center m-0">
          <div className="banner col-lg-10 col-md-10 col-12">
            <h1 className="banner__heading col-12">
              Virtual Polytechnic Courses
              <br /> With Recognized Degrees
            </h1>
            <p className="banner__text col-md-9 mx-auto col-12">
              Kuri Africa is a new collaboration between Zuri and KodeCamp that
              offers online learning with real life certificates at affordable
              prices.
            </p>
            <div className="banner__link-container d-flex justify-content-center">
              <Link to="/register" className="banner__link btn rounded-btn">
                Get Started Now
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="header-bottom col-12">
        <div className="container">
          <div className="row align-items-start justify-content-lg-start justify-content-md-start justify-content-center m-0">
            {students.map((student, index) => (
              <div className="col-lg-4 col-md-6 col-6" key={index}>
                <div className="col-12 d-flex justify-content-center st-img">
                  <img src={student} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
