import React from "react";
import "./partners.css";
import partner1 from "../../assets/images/partner1.svg";
import partner2 from "../../assets/images/partner2.svg";
import partner3 from "../../assets/images/partner3.svg";
import partner4 from "../../assets/images/partner4.svg";
import partner5 from "../../assets/images/partner5.svg";
import partner6 from "../../assets/images/partner6.svg";
import partner7 from "../../assets/images/partner7.svg";

const Partners = () => {
  const partnerImg = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
  ];
  return (
    <div className="container mb-5">
      <h6 className="col-12 text-center partner__heading">
        In Partnership With
      </h6>
      <div className="partner__container">
        <div className="row justify-content-between align-items-center m-0 h-100">
          {partnerImg.map((img, index) => (
            <div className="col" key={index}>
              <div className="col partner__img-container">
                <img src={img} alt="partners" className="img-fluid" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
