import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../components";
import "./headertwo.css";

const HeaderTwo = ({
  title,
  text,
  imgUrl,
  button,
  buttonText,
  height,
  path,
}) => {
  return (
    <header
      className="container-fluid header__two"
      style={{
        backgroundImage: `url(${imgUrl})`,
        height: `${height}`,
      }}
    >
      <Navbar textColor={true} />
      <div className="row align-items-center justify-content-center m-0">
        <div className="header__two-banner col-lg-9 col-md-10 col-12 text-center text-white">
          <h1 className="header__two-bannner--heading">{title}</h1>
          <p className="header__two-bannner--text">{text}</p>
          {button && (
            <div className="col-12 d-flex justify-content-center">
              <Link className="btn rounded-btn" to={path}>
                {buttonText}
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
