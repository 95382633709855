import React from "react";
import { Link } from "react-router-dom";
import "./login.css";

const Login = () => {
  return (
    <div className="container-fluid register">
      <div className="row justify-content-center m-0">
        <div className="col-md-6 col-12 left d-lg-flex d-md-none d-none">
          <div className="col-12 text-center">
            <h1>
              Welcome to <span>Kuri Africa</span>
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque
              vestibulum pellentesque nisi, habitasse maecenas.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-12 right">
          <div className="form-container col-10">
            <h4 className="form-title">Welcome back</h4>
            <p className="form-text">Please input your login details below</p>
            <form className="auth-form col-lg-10 col-md-12 col-12">
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                </label>
              </div>
              <div className="d-flex justify-content-center mt-5">
                <button className="btn rounded-btn col-5">Login</button>
              </div>
              <p className="text-center mt-5">
                <Link className="form-link" to="/">
                  Forgot password?
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
