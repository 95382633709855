import React from "react";
import { HeaderTwo, Footer } from "../../containers";
import { ContactForm } from "../../components";
import "./about.css";
import aboutBg from "../../assets/images/about-bg.png";
import bgOne from "../../assets/images/about-image-1.png";
import bgTwo from "../../assets/images/about-image-2.png";
import mail from "../../assets/images/mail.svg";
import location from "../../assets/images/Location.svg";

const About = () => {
  const [title, text, buttonText] = [
    "Hi! We are Kuri Africa",
    "kuri is a digital capacity development company that gives people the opportunity to transform their potentials to gainful benefits while obtaining a traditional academic certification in the process",
    "",
  ];

  const info = [
    { title: "info@kuri.africa", imgUrl: mail },
    { title: "Abuja", imgUrl: location },
  ];

  return (
    <>
      <HeaderTwo
        title={title}
        text={text}
        imgUrl={aboutBg}
        button={false}
        buttonText={buttonText}
        height={"70vh"}
      />
      <main className="container-fluid my-5">
        <div className="container">
          <div className="row justify-content-center m-0">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row justify-content-between m-0">
                <div
                  className="col-lg-5 col-md-6 col-12 bg about-bgi"
                  style={{
                    backgroundImage: `url(${bgOne})`,
                  }}
                />
                <div className="col-lg-7 col-md-6 col-12 d-flex justify-content-start justify-content-lg-end">
                  <div className="col-lg-11 col-md-11 col-12">
                    <h1 className="secondary-heading col-12">
                      Virtual Polytechnic Courses <br /> With
                      <span className="bg-lyellow text-center">
                        Recognized Degrees
                      </span>
                    </h1>
                    <p className="col-12 paragraph">
                      Kuri is a digital capacity development company that gives
                      people the opportunity to transform their potentials to
                      gainful benefits while obtaining traditional academic
                      certification in the process
                    </p>
                    <p className="col-12 paragraph">
                      In addition to gaining knowledge and obtaining a diploma,
                      this program is also designed as a pipeline to supply a
                      quality workforce to meet global demand for tech talents
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center m-0 mt-5">
                <div className="col-lg-7 col-md-6 col-12 mt-5">
                  <h1 className="secondary-heading col-12">
                    Our
                    <span className="bg-lyellow text-center">Mission</span>
                  </h1>
                  <p className="col-12 paragraph">
                    Our mission is to admit, train, empower and supply quality
                    manpower to deliver world class services globally
                  </p>
                </div>
                <div
                  className="col-lg-5 col-md-6 col-12 mt-5 bg about-bgi"
                  style={{
                    backgroundImage: `url(${bgTwo})`,
                  }}
                />
              </div>
              <div className="row justify-content-center m-0 mt-5">
                <div className="col-12 mt-5">
                  <h1 className="secondary-heading col-12">
                    What is a
                    <span className="bg-lyellow text-center">
                      National Diploma
                    </span>
                  </h1>
                  <p className="col-12 paragraph">
                    A diploma refers to the document awarded to a students after
                    successfully complete present coursework related to a
                    particular topic, field or industry. Diplomas are often
                    awarded by academic institutions after a degree program is
                    completed.
                  </p>
                  <p className="col-12 paragraph">
                    Nigeria, officially the Federal Republic of Nigeria, is a
                    federal constitutional republic comprising 36 states and its
                    Federal Capital Territory, Abuja.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center m-0 mt-5">
                <div className="col-12 mt-5">
                  <div className="row align-items-center justify-content-center m-0">
                    <div className="col-lg-6 col-md-6 col-12">
                      <h1 className="secondary-heading col-12 mb-4">
                        Leave a Message
                      </h1>
                      <div className="col-lg-8 col-12">
                        <ContactForm />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-5 mt-lg-0 mt-md-0">
                      <h1 className="secondary-heading col-12 mb-4">
                        Information
                      </h1>
                      <ul className="col-12 p-0 info-list">
                        {info.map((i) => (
                          <li className="d-flex align-items-center">
                            <img src={i.imgUrl} alt="" />
                            <span>{i.title}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default About;
