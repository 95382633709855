import React from "react";
import { Link } from "react-router-dom";
import "./explore.css";
import student6 from "../../assets/images/student6.svg";

import { Ready } from "../../components";

const Explore = () => {
  return (
    <div className="container-fluid mb-5">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <h1 className="primary-heading text-center mb-4">
            Explore Our
            <span className="bg-lyellow mx-2 px-3">Courses</span>
          </h1>
          <p className="text-center work-sub-text">
            These are the programs can be found at Kuri Africa For Now.
            <br /> More programs coming soon...
          </p>
        </div>
        <div className="col-12 course-sample">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6">
              <img
                src={student6}
                alt=""
                className="img-fluid course-sample-img"
              />
            </div>
            <div className="col-lg-6">
              <h5 className="courser-sample title">Software Engineering</h5>
              <p className="course-sample-text">
                Learning by doing. Our learning philosophy doesn’t take offline
                learning online. With our challenge-based learning method,
                you'll get hands-on experience solving our world's grandest
                problems through practical application under the guidance of
                industry experts and leaders.
              </p>
              <div className="col-12 d-flex justify-content-start mt-4">
                <Link
                  to="/register"
                  className="banner__link btn rounded-btn course-sample-link"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center my-5">
        <Link to="/programme" className="banner__link btn rounded-btn">
          See All Courses
        </Link>
      </div>
      <Ready />
    </div>
  );
};

export default Explore;
