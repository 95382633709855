import React from "react";
import {
  Header,
  Footer,
  WhyKuri,
  Testimonial,
  Benefit,
  HowItWorks,
  Explore,
  Questions,
} from "../../containers";
import { Navbar, Partners } from "../../components";
import "./home.css";

const Home = () => {
  return (
    <>
      <div className="bg-header bgi">
        <Navbar textColor={false} />
        <Header />
      </div>
      <div className="main-content">
        <Partners />
        <WhyKuri />
        <Testimonial />
        <Benefit />
        <HowItWorks />
        <Explore />
        <Questions />
      </div>
      <Footer />
    </>
  );
};

export default Home;
