import React from "react";
import { Link } from "react-router-dom";
import { Footer, TabsContainer } from "../../containers";
import { Navbar, Slider, QuestionForm } from "../../components";
import "./programme.css";
import student6 from "../../assets/images/student6.svg";
import student8 from "../../assets/images/student8.svg";
import duration from "../../assets/images/duration.svg";
import certificate from "../../assets/images/certificate.svg";
import language from "../../assets/images/language.svg";
import number from "../../assets/images/number.svg";

const Programme = () => {
  const overviews = [
    { id: 1, label: "Duration", text: "20 Months", imgUrl: duration },
    { id: 2, label: "Language", text: "English", imgUrl: language },
    { id: 3, label: "Cohort Size", text: "Max. 25 Learners", imgUrl: number },
    {
      id: 4,
      label: "Accreditation",
      text: "National Diploma",
      imgUrl: certificate,
    },
  ];
  return (
    <>
      <header className="container-fluid p-0 bg-header bgi cheader">
        <Navbar textColor={false} />
        <div className="banner2 container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12">
              <h1 className="banner2-heading">Software Engineering</h1>
              <p className="banner2-text col-10">
                Take your first step towards a career in software development
                with this introduction to Java—one of the most in-demand
                programming languages and the foundation of the Android
                operating system.
              </p>
              <Link to="/register" className="btn rounded-btn">
                Get Started Now
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <img src={student6} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </header>
      <main className="container-fluid p-0">
        <div className="container py-5">
          <div className="row align-items-center justify-content-center m-0 py-5">
            <div className="col-lg-5 col-md-5 col-12">
              <h1 className="primary-heading col-12">
                A Little
                <div className="bg-lyellow text-center">Introduction</div>
              </h1>
            </div>
            <div className="col-lg-7 col-md-7 col-12">
              <p className="col-12 paragraph">
                Designed for beginners, this Specialization will teach you core
                programming concepts and equip you to write programs to solve
                complex problems. In addition, you will gain the foundational
                skills a software engineer needs to solve real-world problems,
                from designing algorithms to testing and debugging your
                programs.
              </p>
            </div>
          </div>
          <div className="row align-items-center justify-content-center m-0 py-5">
            <div className="col-12 text-center">
              <h1 className="primary-heading col-12">
                <span className="bg-lyellow text-center">Why</span> Software
                Engineering
              </h1>
            </div>
            <div className="col-lg-9 col-md-9 col-12 mt-5">
              <TabsContainer />
            </div>
          </div>
        </div>
        <div className="col-12 bg-lyellow py-5">
          <h1 className="primary-heading col-12 text-black text-center">
            Programme
            <span className="bg-black text-white text-center"> Overview</span>
          </h1>
          <p className="col-12 text-center paragraph mb-4">
            Everything you need to know about the program
          </p>
          <div className="col-lg-8 col-md-10 col-12 mx-auto">
            <div className="row justify-content-centerr">
              {overviews.map((overview) => (
                <div className="col-lg-6 col-md-6 col-12" key={overview.id}>
                  <div className="overview col-12">
                    <div className="col-10">
                      <div className="col-12 overview-label">
                        {overview.label}
                      </div>
                      <div className="col-12 overview-text">
                        {overview.text}
                      </div>
                    </div>
                    <div className="col-2">
                      <img src={overview.imgUrl} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 py-5">
          <div className="container">
            <h1 className="primary-heading col-lg-4">
              Hear From Our
              <div className="bg-lyellow">Past Students</div>
            </h1>
            <Slider mentors={true} />
          </div>
        </div>
        <div className="container py-5">
          <div className="col-lg-10 col-md-10 col-12">
            <div className="row justify-content-center">
              <div className="col-lg-5 mt-3">
                <img src={student8} alt="" className="img-fluid" />
              </div>
              <div className="col-lg-7 mt-3">
                <QuestionForm enroll={true} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Programme;
