import React from "react";
import "./filterfaq.css";
import searchImg from "../../assets/images/search.svg";

const FilterFaq = () => {
  return (
    <div className="container d-flex justify-content-center mb-5">
      <form className="col-lg-7 col-md-10 col-12">
        <div className="input-group mb-3">
          <img src={searchImg} alt="" height={15} />
          <input
            type="search"
            className="form-control"
            placeholder="search for frequently asked questions"
          />
          <button className="btn btn-outline-secondary" type="submit">
            Button
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterFaq;
