import React from "react";
import { Link } from "react-router-dom";
import "./ready.css";

const Ready = () => {
  return (
    <div className="container">
      <div className="col-12 ready">
        <div className="row justify-content-between m-0">
          <div className="col-lg-9">
            <h1 className="primary-heading text-white text-center text-md-start text-lg-start">
              Are You Ready To Start Your Course Now?
            </h1>
          </div>
          <div className="col-lg-3">
            <div className="d-flex justify-content-center justify-content-md-start justify-content-lg-start">
              <Link to="/register" className="btn rounded-btn">
                Let’s Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ready;
