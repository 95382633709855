import React from "react";
import "./howitworks.css";

const HowItWorks = () => {
  return (
    <div className="container-fluid bg-lyellow py-5 my-5">
      <div className="container">
        <div className="row justify-content-center">
          <h1 className="primary-heading text-center mb-4">
            <span className="bg-black text-white mr-1">How</span>
            It Works
          </h1>
          <p className="text-center work-sub-text">
            You can start learning in 2 simple steps
          </p>
          <div className="col-lg-8 col-md-10 col-12">
            <div className="row justify-content-center">
              <div className="col-lg-5 mb-3">
                <div className="work-card col-12">
                  <span className="work-batch">1</span>
                  <p className="work-text">
                    Click on the Get started button to fill in your application.
                    Enter your details and choose your preferred program.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 mb-3">
                <div className="work-card col-12">
                  <span className="work-batch">2</span>
                  <p className="work-text">
                    After selecting your preferred program, The next page shows
                    you where you can pay the application fee. You can either
                    choose to pay first term fees or pay later
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
