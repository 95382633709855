import React from "react";
import "./contactform.css";

const ContactForm = () => {
  return (
    <form className="col-12 contact-form">
      <div className="mb-3">
        <label className="col-12">
          Name:
          <input type="text" className="form-control col-12" name="name" />
        </label>
      </div>
      <div className="mb-3">
        <label className="col-12">
          Email Address:
          <input type="email" className="form-control col-12" name="email" />
        </label>
      </div>
      <div className="mb-3">
        <label className="col-12">
          Message:
          <textarea
            type="text"
            className="form-control col-12"
            name="message"
          />
        </label>
      </div>
      <div className="mb-0">
        <button type="submit" className="btn rounded-btn col-12">
          Send Message
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
