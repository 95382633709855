import React from "react";
import "./questionform.css";

const QuestionForm = ({ enroll }) => {
  return (
    <form className="col-12 form">
      <div className="mb-3">
        <label className="col-12">
          <input
            type="text"
            className={enroll ? "form-control border-black" : "form-control"}
            name="fName"
            placeholder="First Name"
          />
        </label>
      </div>
      <div className="mb-3">
        <label className="col-12">
          <input
            type="text"
            className={enroll ? "form-control border-black" : "form-control"}
            name="lName"
            placeholder="Last Name"
          />
        </label>
      </div>
      <div className="mb-3">
        <label className="col-12">
          <input
            type="email"
            className={enroll ? "form-control border-black" : "form-control"}
            name="email"
            placeholder="Email"
          />
        </label>
      </div>
      <div className="mb-3 row">
        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
          <label className="col-12">
            <select
              className={enroll ? "form-control border-black" : "form-control"}
            >
              <option value="country">country</option>
              <option value="lime">Lime</option>
              <option value="coconut">Coconut</option>
              <option value="mango">Mango</option>
            </select>
          </label>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
          <label className="col-12">
            <input
              type="number"
              className={enroll ? "form-control border-black" : "form-control"}
              name="phone"
              placeholder="Phone"
            />
          </label>
        </div>
      </div>
      {!enroll && (
        <div className="mb-3">
          <label className="col-12">
            <textarea
              type="text"
              className={enroll ? "form-control border-black" : "form-control"}
              name="message"
              placeholder="Message"
            />
          </label>
        </div>
      )}
      {enroll && (
        <div className="mb-3">
          <label className="col-12">
            <select
              className={enroll ? "form-control border-black" : "form-control"}
            >
              <option value="choose program">choose program</option>
              <option value="lime">Lime</option>
              <option value="coconut">Coconut</option>
              <option value="mango">Mango</option>
            </select>
          </label>
        </div>
      )}
      <div className="row m-0">
        <button type="submit" className="btn rounded-btn">
          Send Message
        </button>
      </div>
    </form>
  );
};

export default QuestionForm;
