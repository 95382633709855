import React from "react";
import { Slider } from "../../components";

const Testimonial = () => {
  return (
    <div className="container">
      <div className="col-12">
        <h1 className="primary-heading col-lg-4">
          Hear From Our
          <div className="bg-lyellow">Past Students</div>
        </h1>
      </div>
      <Slider mentors={false} />
    </div>
  );
};

export default Testimonial;
