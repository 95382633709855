import React from "react";
import { QuestionForm } from "../../components";
import "./questions.css";
import student7 from "../../assets/images/student7.svg";

const Questions = () => {
  return (
    <div className="container-fluid mt-4 py-5 bg-lyellow">
      <h1 className="primary-heading col-lg-9 text-center mb-4">
        Got
        <span className="bg-black text-white">Questions?</span>
      </h1>
      <div className="col-12 mt-5">
        <div className="row align-items-start justify-content-center">
          <div className="col-lg-4">
            <div className="col-12 p-0 question-img-container">
              <img src={student7} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-12 question-form-container">
              <QuestionForm enroll={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
