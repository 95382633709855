import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { HeaderTwo, Footer } from "../../containers";
import { FilterFaq } from "../../components";
import "./faq.css";
import faqBg from "../../assets/images/faq-bg.png";

const Faq = () => {
  const [title, text, buttonText] = [
    "Frequently asked questions",
    "Got a question? We are here to answer! If you do not see your question here you can contact us.",
    "Chat with us now",
  ];
  const faqContent = [
    {
      id: 1,
      title: "What are the admission requirements?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor non, enim nulla malesuada nulla etiam. Lacus turpis sit dui orci vitae, nulla varius. Sem suspendisse pulvinar morbi vel augue facilisis fermentum. Interdum aliquam viverra dictumst ultrices leo eget aliquam vulputate rhoncus. Amet velit varius in purus velit orci praesent massa. Id posuere non hac duis iaculis eu non tellus lacinia. Nibh id diam et.",
    },
    {
      id: 2,
      title: "When is the program commencing?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor non, enim nulla malesuada nulla etiam. Lacus turpis sit dui orci vitae, nulla varius. Sem suspendisse pulvinar morbi vel augue facilisis fermentum. Interdum aliquam viverra dictumst ultrices leo eget aliquam vulputate rhoncus. Amet velit varius in purus velit orci praesent massa. Id posuere non hac duis iaculis eu non tellus lacinia. Nibh id diam et.",
    },
  ];
  return (
    <>
      <HeaderTwo
        title={title}
        text={text}
        imgUrl={faqBg}
        button={true}
        buttonText={buttonText}
        height={"100vh"}
        path="/"
      />
      <main className="container-fluid my-5 p-0">
        <FilterFaq />
        <div className="container">
          <h1 className="secondary-heading col-12 text-center mb-5">
            Common
            <span className="bg-lyellow text-center">Questions</span>
          </h1>
          <Accordion defaultActiveKey="0" flush>
            {faqContent.map((faq) => (
              <Accordion.Item eventKey={faq.id} key={faq.id}>
                <Accordion.Header>{faq.title}</Accordion.Header>
                <Accordion.Body>{faq.content}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="fcta col-12 mt-5">
          <h1 className="fcta-title text-center">
            If your question is not listed here or you have more questions,
            Please contact us
          </h1>
          <div className="col-12 d-flex justify-content-center mt-3">
            <Link className="btn rounded-btn mt-3" to="/faq">
              Contact us now
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Faq;
