import React from "react";
import "./tabs.css";

const Tabs = ({ title, text }) => {
  return (
    <div className="tabs-content p-5">
      <h5 className="tabs-content-title">{title}</h5>
      <p className="tabs-content-text">{text}</p>
    </div>
  );
};

export default Tabs;
