import React from "react";
import "./whykuri.css";

import num1 from "../../assets/images/one.svg";
import num2 from "../../assets/images/two.svg";
import num3 from "../../assets/images/three.svg";
import num4 from "../../assets/images/four.svg";
import num5 from "../../assets/images/five.svg";
import num6 from "../../assets/images/six.svg";

const WhyKuri = () => {
  const whyArr = [
    {
      img: num1,
      title: "Course Accessibility",
      desc: "Select  a suitable course from a vast area of other courses",
    },
    {
      img: num2,
      title: "Course Accessibility",
      desc: "Select  a suitable course from a vast area of other courses",
    },
    {
      img: num3,
      title: "Expert Instructors",
      desc: "Select  a suitable course from a vast area of other courses",
    },
    {
      img: num4,
      title: "Recorded Lessons",
      desc: "Select  a suitable course from a vast area of other courses",
    },
    {
      img: num5,
      title: "Practical Learning",
      desc: "Select  a suitable course from a vast area of other courses",
    },
    {
      img: num6,
      title: "Recognized Certification",
      desc: "Select  a suitable course from a vast area of other courses",
    },
  ];
  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-start m-0">
        <div className="col-lg-3">
          <h1 className="primary-heading">
            Why Choose
            <div className="bg-lyellow text-center">Kuri Africa?</div>
          </h1>
        </div>
        <div className="col-lg-9">
          <div className="row justify-content-center m-0">
            {whyArr.map((w) => (
              <div className="col-lg-4 col-md-4 col-12">
                <div className="why-card col-12">
                  <div className="why-card-batch">
                    <img src={w.img} alt="" />
                  </div>
                  <div className="why-card-title">{w.title}</div>
                  <div className="why-card-paragraph">{w.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyKuri;
