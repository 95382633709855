import React from "react";
import { Link } from "react-router-dom";
import "./benefit.css";

import student4 from "../../assets/images/student4.svg";
import student5 from "../../assets/images/student5.svg";

const Benefit = () => {
  const benefits = [
    "100% online 365 days a year.",
    "Study flexibly & efficiently alongside your job.",
    "Distance learning experts for over 60 years.",
    "All courses are accredited and state-recognized.",
    "Optimal learning material and exams online.",
    "Modern and Fun Learning Environment.",
  ];
  return (
    <div className="container mb-5">
      <div className="row align-items-start m-0 mt-5">
        <div className="col-lg-6 col-md-6 col-12 mb-4">
          <h1 className="primary-heading col-lg-9">
            <div className="bg-lyellow">Graduate job-ready</div>
            with the confidence to get hired.
          </h1>
          <p className="col-lg-9 benefit-text">
            Build the skills, competencies, and mindset needed in the
            21st-century with our fully-remote and flexible bachelor's and
            master's degrees. You'll learn how to make a sustainable impact on
            society via a world-class network of experts and cutting edge
            learning platform. Join our community of change makers.
          </p>
          <div className="col-12 benefit-link">
            <Link to="/register" className="btn rounded-btn">
              Let’s Get Started
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-4">
          <img src={student4} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="row align-items-start m-0 mt-5">
        <div className="col-lg-6 col-md-6 col-12 mb-4">
          <img src={student5} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-4">
          <h1 className="primary-heading">
            Learn In a Fun
            <div className="bg-lyellow">Modern Way</div>
          </h1>
          <ul className="benefit-list">
            {benefits.map((benefit, index) => (
              <li className="benefit-item" key={index}>
                <span>
                  <i className="bi bi-check-lg text-white"></i>
                </span>
                <p>{benefit}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
