import { Routes, Route } from "react-router-dom";
// import { Navbar } from "./components";
import { Home, About, Faq, Login, Register, Programme } from "./routes";

import "./App.css";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Navbar />}> */}
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/programme" element={<Programme />} />
        {/* </Route> */}
      </Routes>
    </>
  );
}

export default App;
