import React from "react";
import { Link } from "react-router-dom";
import { Socials } from "../../components";
import "./footer.css";
import logo from "../../assets/images/logo-white.svg";

const Footer = () => {
  return (
    <div className="container-fluid bg-black footer">
      <div className="row justify-content-center m-0">
        <div className="col-12 footer-header">
          <div className="row justify-content-center m-0">
            <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center align-items-center footer-container">
              <img src={logo} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-2 col-md-2 col-6 footer-container">
              <ul className="foote-list-container">
                <li className="footer-list">
                  <Link to="/programme" className="footer-link">
                    Courses
                  </Link>
                </li>
                <li className="footer-list">
                  <Link to="/faq" className="footer-link">
                    FAQs
                  </Link>
                </li>
                <li className="footer-list">
                  <Link to="/faq" className="footer-link">
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-6 footer-container">
              <ul className="foote-list-container">
                <li className="footer-list">
                  <Link to="#" className="footer-link">
                    Blog
                  </Link>
                </li>
                <li className="footer-list">
                  <Link to="#" className="footer-link">
                    Careers
                  </Link>
                </li>
                <li className="footer-list">
                  <Link to="#" className="footer-link">
                    News and Press
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-12 footer-container">
              <h6 className="footer-heading text-lg-start text-md-start text-center">
                Follow Us On
              </h6>
              <Socials />
            </div>
          </div>
        </div>
        <div className="col-12 footer-bottom">
          <div className="row justify-content-center">
            <p className="col-12 text-center">© 2022 Kuri Africa </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
