import React from "react";
import "./socials.css";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram-fill.svg";
import linkedin from "../../assets/images/linkedin-box-fill.svg";
import twitter from "../../assets/images/twitter-fill.svg";

const Socials = () => {
  const socialIcons = [facebook, instagram, linkedin, twitter];
  return (
    <ul className="socials">
      {socialIcons.map((icon, index) => (
        <li className="social-list" key={index}>
          <a href="#" className="social-link">
            <img src={icon} alt="" className="img-fluid" />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Socials;
