import React, { useState } from "react";
import { Tabs } from "../../components";
import "./tabscontainer.css";

const TabsContainer = () => {
  const [showTab1, setShowTab1] = useState(false);
  const [showTab2, setShowTab2] = useState(false);
  const [showTab3, setShowTab3] = useState(false);
  const [showTab4, setShowTab4] = useState(false);
  const [showTab5, setShowTab5] = useState(false);
  const [showTab6, setShowTab6] = useState(false);

  const handleTabShow1 = () => {
    setShowTab1(true);
    setShowTab2(false);
    setShowTab3(false);
    setShowTab4(false);
    setShowTab5(false);
    setShowTab6(false);
  };
  const handleTabShow2 = () => {
    setShowTab2(true);
    setShowTab1(false);
    setShowTab3(false);
    setShowTab4(false);
    setShowTab5(false);
    setShowTab6(false);
  };
  const handleTabShow3 = () => {
    setShowTab3(true);
    setShowTab1(false);
    setShowTab2(false);
    setShowTab4(false);
    setShowTab5(false);
    setShowTab6(false);
  };
  const handleTabShow4 = () => {
    setShowTab4(true);
    setShowTab1(false);
    setShowTab2(false);
    setShowTab3(false);
    setShowTab5(false);
    setShowTab6(false);
  };
  const handleTabShow5 = () => {
    setShowTab5(true);
    setShowTab1(false);
    setShowTab2(false);
    setShowTab3(false);
    setShowTab4(false);
    setShowTab6(false);
  };
  const handleTabShow6 = () => {
    setShowTab6(true);
    setShowTab1(false);
    setShowTab2(false);
    setShowTab3(false);
    setShowTab4(false);
    setShowTab5(false);
  };

  return (
    <div className="tabs col-12">
      <div className="row justify-content-center align-items-start">
        <div className="col-lg-4 col-md-4 col-12">
          <ul className="tabs-nav">
            <li
              onClick={handleTabShow1}
              className={showTab1 ? "tab-list active" : "tab-list"}
            >
              Job opportunities
            </li>
            <li
              onClick={handleTabShow2}
              className={showTab2 ? "tab-list active" : "tab-list"}
            >
              Broad Field
            </li>
            <li
              onClick={handleTabShow3}
              className={showTab3 ? "tab-list active" : "tab-list"}
            >
              Team Work
            </li>
            <li
              onClick={handleTabShow4}
              className={showTab4 ? "tab-list active" : "tab-list"}
            >
              Problem Solving
            </li>
            <li
              onClick={handleTabShow5}
              className={showTab5 ? "tab-list active" : "tab-list"}
            >
              Amazing Community
            </li>
            <li
              onClick={handleTabShow6}
              className={showTab6 ? "tab-list active" : "tab-list"}
            >
              Great Pay
            </li>
          </ul>
        </div>
        <div className="col-lg-8 col-md-8 col-12">
          <div className="tabs-output">
            {showTab1 && (
              <Tabs
                title="Job Opportunities"
                text="Companies are always looking for good software developers, so if you take the program seriously enough and come out great, gettong a job would be the least thing to attend to,."
              />
            )}
            {showTab2 && (
              <Tabs
                title="Broad Field"
                text="Companies are always looking for good software developers, so if you take the program seriously enough and come out great, gettong a job would be the least thing to attend to,."
              />
            )}
            {showTab3 && (
              <Tabs
                title="Team Work"
                text="Companies are always looking for good software developers, so if you take the program seriously enough and come out great, gettong a job would be the least thing to attend to,."
              />
            )}
            {showTab4 && (
              <Tabs
                title="Problem Solving"
                text="Companies are always looking for good software developers, so if you take the program seriously enough and come out great, gettong a job would be the least thing to attend to,."
              />
            )}
            {showTab5 && (
              <Tabs
                title="Amazing Community"
                text="Companies are always looking for good software developers, so if you take the program seriously enough and come out great, gettong a job would be the least thing to attend to,."
              />
            )}
            {showTab6 && (
              <Tabs
                title="Great Pay"
                text="Companies are always looking for good software developers, so if you take the program seriously enough and come out great, gettong a job would be the least thing to attend to,."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabsContainer;
