import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./slider.css";

import play from "../../assets/images/play.svg";
import test1 from "../../assets/images/test-1.svg";
import test2 from "../../assets/images/test-2.svg";
import test3 from "../../assets/images/test-3.svg";
import mentor1 from "../../assets/images/mentor1.svg";
import mentor2 from "../../assets/images/mentor2.svg";
import mentor3 from "../../assets/images/mentor3.svg";
import mentor4 from "../../assets/images/mentor4.svg";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const testimonial = [
  <div className="col-12" data-value="1">
    <div className="mentor-item">
      <div className="mentor-item-header">
        <div className="img">
          <img src={mentor1} alt="" className="img-fluid" />
        </div>
        <div className="mentor-item-title">Jennifer Higgens</div>
        <div className="mentor-item-desc">Software Engineer, Apple</div>
      </div>
    </div>
  </div>,

  <div className="col-12" data-value="2">
    <div className="mentor-item">
      <div className="mentor-item-header">
        <div className="img">
          <img src={mentor2} alt="" className="img-fluid" />
        </div>
        <div className="mentor-item-title">Jennifer Higgens</div>
        <div className="mentor-item-desc">Software Engineer, Apple</div>
      </div>
    </div>
  </div>,

  <div className="col-12" data-value="3">
    <div className="mentor-item">
      <div className="mentor-item-header">
        <div className="img">
          <img src={mentor3} alt="" className="img-fluid" />
        </div>
        <div className="mentor-item-title">Jennifer Higgens</div>
        <div className="mentor-item-desc">Software Engineer, Apple</div>
      </div>
    </div>
  </div>,

  <div className="col-12" data-value="4">
    <div className="mentor-item">
      <div className="mentor-item-header">
        <div className="img">
          <img src={mentor4} alt="" className="img-fluid" />
        </div>
        <div className="mentor-item-title">Jennifer Higgens</div>
        <div className="mentor-item-desc">Software Engineer, Apple</div>
      </div>
    </div>
  </div>,
  <div className="col-12" data-value="5">
    <div className="mentor-item">
      <div className="mentor-item-header">
        <div className="img">
          <img src={mentor2} alt="" className="img-fluid" />
        </div>
        <div className="mentor-item-title">Jennifer Higgens</div>
        <div className="mentor-item-desc">Software Engineer, Apple</div>
      </div>
    </div>
  </div>,
];

const items = [
  <div className="col-12" data-value="1">
    <div className="slide-item">
      <div className="slide-item-header">
        <div className="row align-items-center">
          <div className="col-2 img">
            <img src={test1} alt="" className="img-fluid" />
          </div>
          <div className="col-9">
            <div className="slide-item-title">Nancy Hewings</div>
            <div className="slide-item-desc">Software Engineer, Google</div>
          </div>
        </div>
      </div>
      <div className="slide-item-body">
        <button className="play btn">
          <img src={play} alt="" />
        </button>
      </div>
    </div>
  </div>,
  <div className="col-12" data-value="2">
    <div className="slide-item">
      <div className="slide-item-header">
        <div className="row align-items-center">
          <div className="col-2 img">
            <img src={test2} alt="" className="img-fluid" />
          </div>
          <div className="col-9">
            <div className="slide-item-title">Nancy Hewings</div>
            <div className="slide-item-desc">Software Engineer, Google</div>
          </div>
        </div>
      </div>
      <div className="slide-item-body">
        <button className="play btn">
          <img src={play} alt="" />
        </button>
      </div>
    </div>
  </div>,
  <div className="col-12" data-value="3">
    <div className="slide-item">
      <div className="slide-item-header">
        <div className="row align-items-center">
          <div className="col-2 img">
            <img src={test1} alt="" className="img-fluid" />
          </div>
          <div className="col-9">
            <div className="slide-item-title">Nancy Hewings</div>
            <div className="slide-item-desc">Software Engineer, Google</div>
          </div>
        </div>
      </div>
      <div className="slide-item-body">
        <button className="play btn">
          <img src={play} alt="" />
        </button>
      </div>
    </div>
  </div>,
  <div className="col-12" data-value="4">
    <div className="slide-item">
      <div className="slide-item-header">
        <div className="row align-items-center">
          <div className="col-2 img">
            <img src={test3} alt="" className="img-fluid" />
          </div>
          <div className="col-9">
            <div className="slide-item-title">Nancy Hewings</div>
            <div className="slide-item-desc">Software Engineer, Google</div>
          </div>
        </div>
      </div>
      <div className="slide-item-body">
        <button className="play btn">
          <img src={play} alt="" />
        </button>
      </div>
    </div>
  </div>,
];

const Slider = ({ mentors }) => {
  return (
    <>
      {!mentors && (
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="alternate"
        />
      )}
      {mentors && (
        <AliceCarousel
          mouseTracking
          items={testimonial}
          responsive={responsive}
          controlsStrategy="alternate"
        />
      )}
    </>
  );
};

export default Slider;
