import React from "react";
import "./register.css";

const Register = () => {
  return (
    <div className="container-fluid register">
      <div className="row justify-content-center m-0">
        <div className="col-md-6 col-12 left d-lg-flex d-md-none d-none">
          <div className="col-12 text-center">
            <h1>
              Welcome to <span>Kuri Africa</span>
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque
              vestibulum pellentesque nisi, habitasse maecenas.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-12 right">
          <div className="form-container col-10">
            <h4 className="form-title">Create an account</h4>
            <p className="form-text">Please fill with your details</p>
            <form className="auth-form col-lg-10 col-md-12 col-12">
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <p className="password-text mt-1">
                    Password should contain at least 8 characters, no spaces,
                    must contain 1 uppercase, 1 lowercase, numbers and symbols
                  </p>
                </label>
              </div>
              <div className="mb-3">
                <label className="col-12">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Re-enter password"
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="col-12 form-check">
                  <input type="checkbox" className="form-check-input" />
                  Please accept <span>our Terms and Conditions</span>
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn rounded-btn col-6">Register</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
